const Information = () => {
    return (
        <>
        <div className="creamBackground" id="information">
            <div id="ceremony">
                <h1 className="hotPink">Ceremony</h1>
                <div id="ceremonyBackground">
                    <p>The pleasure of your company is requested to celebrate the marriage of </p><h3 className="italics">William Titford</h3><h3 className="italics">&</h3><h3 className="italics">Crystal Taylor</h3>
                    <p>at <i><b>222 Upper Waiwera Road, Silverdale, Auckland</b></i> on Saturday, 22nd February, 2025</p>
                    <p>The ceremony begins at 3:00PM, afterward we will capture some beautiful photos including moments with our guests.</p>
                </div>
            </div>
            <div id="reception">
                <h1 className="hotPink">Reception</h1>
                <div id="reception">
                    <p>While we take photos, feel free to relax with a drink in the company of family and friends. Dinner will be served at approximately 5:00PM. As we are using small local caterers we may struggle to accommodate all dietary requirements. We'll enjoy a delightful meal, followed by dancing and a bonfire (weather permitting) that will go late into the evening.</p>
                    <p>The dress code is casual, bring your gumboots! And children are welcome too.</p>
                </div>
            </div>
            <div id="camping"> 
                <h1 className="hotPink">Camping</h1>
                <div id="camping">
                    <p>For those who wish to stay overnight, bring your tents and campervans and camp out in one of the paddocks.</p>
                </div>
            </div>
        </div>
        </>
    )
  };
  
  export default Information;