import React from "react";
import { Link } from "react-router-dom";

const Navigation = ({ closeMenu }) => {

  const handleLinkClick = () => {
    closeMenu();
  };

  return (
    <nav className="navbar">
      <ul className="navbar-links">
        <li><Link to="/information" onClick={handleLinkClick}>Information</Link></li>
        <li><Link to="/guest_rsvp" onClick={handleLinkClick}>RSVP</Link></li>
        <li><Link to="/gifts" onClick={handleLinkClick}>Gifts</Link></li>
        <li><Link to="/contact" onClick={handleLinkClick}>Contact Us</Link></li>
      </ul>
    </nav>
  );
};

export default Navigation;