const Gifts = () => {
    return (
      <>
      <div className="creamBackground" id="gifts">
        <h1 className="hotPink">Gifts</h1>
        <p>
        We're so excited to celebrate our wedding with you! Your presence is the greatest gift we could ask for. If you feel inclined to bless us with a gift, we would appreciate contributions towards our future and baby expenses, as we’re expecting our first child in April. Thank you for your love and support!
        </p>
      </div>
      <div className="creamBackground" id="bank_details">
        <p>
          For gifts please deposit into the following account:<br></br>
          ANZ<br></br>
          Mr W D Titford & Miss C G Taylor<br></br>
          01-0186-0581885-00
        </p>
      </div>
      </>
    );
  };
  
  export default Gifts;