import './App.css';

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Import components/pages from your project
import Layout from "./pages/Layout";
import Information from "./pages/Information";
import Guest_RSVP from "./pages/Guest_RSVP";
import Gifts from "./pages/Gifts";
import Contact from "./pages/Contact";


function App() {
  return (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Information />} />
        <Route path="Information" element={<Information />} />
        <Route path="Guest_Rsvp" element={<Guest_RSVP />} />
        <Route path="Gifts" element={<Gifts />} />
        <Route path="Contact" element={<Contact />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
