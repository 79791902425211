import { Outlet, Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Navigation from "../components/Navigation";
import { IoClose, IoMenu } from "react-icons/io5";
import React, { useState } from "react";


const Layout = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (    
        <>
        <div id="whole_page_content">
            <div id="main_page_content">
                <nav id="navbar">
                    <NavLink className="nav_logo" onClick={toggleMenu}>
                        {menuOpen ? <IoClose /> : <IoMenu />}
                    </NavLink>
                    <div id="nav_links" className={menuOpen ? "nav_links_open" : "nav_links_closed"}>
                        <Navigation closeMenu={closeMenu} />
                    </div>
                </nav>
                <div id="header">
                    <div id="announcement">
                        <h1>William & Crystal</h1>
                        <h2>22.02.25</h2>
                        <div id="button_div"><Link className="guestRSVP" to="/guest_rsvp">RSVP</Link></div>
                    </div>
                </div>
                <div className="content-container" id="main-content">
                    <Outlet />
                </div>
            </div>
            <div id="footer">
                <div className="footer_container">
                    <div className="left">
                        <Navigation closeMenu={closeMenu} />  
                    </div>
                    <div className="center">
                        
                    </div>
                    <div className="right">
                        
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default Layout;
