import Obfuscate from 'react-obfuscate';

const Contact = () => {
    return (
      <>
      <div id="contactDetails">
        <h1 className="creamBackground">Contact Details</h1>
        <div className="contact_details creamBackground">
          <h2>William</h2>
          <ul>
            <li><b>Email address:</b> <Obfuscate email="w.titford@gmail.com" /></li>
            <li><b>Phone number:</b> <Obfuscate tel="02108114121" /></li>
          </ul>
        </div>
        <div className="contact_details creamBackground">
          <h2>Crystal</h2>
          <ul>
            <li><b>Email address:</b> <Obfuscate email="crystalgtaylor1991@gmail.com" /></li>
            <li><b>Phone number:</b> <Obfuscate tel="0220965960" /></li>
          </ul>
        </div>
      </div>

      </>
    );
  };
  
  export default Contact;